<template>
	<div>
		<section class="section">
			<h2 class="title">Planning de présence pendant le confinement</h2>
			<p>Déclarer les jours où vous serez présent dans les bureaux</p>
		</section>
		<section v-for="jour in jours" class="section">
			<JourPresence :jour="jour"></JourPresence>
		</section>
	</div>
</div>
</template>
<script>
	export default {
		computed : {
			jours() {
				let date, dd, mm, yyyy, jour, today, ret, cle;
				today = new Date();
				date = new Date();
				ret = [];
				while(ret.length < 7) {
					// if(this.jourOuvre(date)) {
						dd = String(date.getDate()).padStart(2, '0');
						mm = String(date.getMonth() + 1).padStart(2, '0');
						yyyy = date.getFullYear();
						cle = dd + '-' + mm + '-' + yyyy;
						ret.push({
							cle : cle,
							date : dd + '/' + mm + '/'+ yyyy,
							nom : this.ucfirst(this.nomJour(date))+' '+dd+' '+this.nomMois(date)+(today.getFullYear() == yyyy ? ' ' + yyyy : '')
						});
					// }
					date.setDate(date.getDate() + 1)
				}		
				return ret;
			}
		}
	}
</script>